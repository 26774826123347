import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  Container,
  Accordion,
  Card,
  Spinner,
  Row,
  Button,
} from 'react-bootstrap';
import { CSVLink } from 'react-csv';

import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import { startLoadAssets } from '../../store/actions';
import CompanyTable from './CompanyTable';

import ExportCSVWorker from './ExportCSVWorker';

const instance = new ExportCSVWorker();

const groupBy = (arr, properties) => {
  return arr.reduce(function (memo, x) {
    if (!memo[x[properties].proprietorName]) {
      memo[x[properties].proprietorName] = [];
    }
    memo[x[properties].proprietorName].push(x);
    return memo;
  }, {});
};

const sortObject = (o) =>
  Object.keys(o)
    .sort()
    .reduce((r, k) => ((r[k] = o[k]), r), {});

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

class Assets extends Component {
  csvLink = React.createRef();
  state = {
    loadingAssets: false,
    loadingUsers: false,
    // headers: [
    //   { label: 'Company Name', key: 'companyName' },
    //   { label: 'Company Number', key: 'companyNumber' },
    //   { label: 'Region', key: 'region' },
    //   { label: 'Number of Buildings', key: 'buildingsNumber' },
    //   { label: 'Owner', key: 'owner' },
    // ],
    csvData: [],
    csvName: '',
  };

  componentDidMount() {
    if (!this.props.assets.length) {
      this.setState({ loadingAssets: true });

      this.props.firebase.getAllAssets().on('value', (snapshot) => {
        this.props.onLoadAssets(snapshot.val());

        this.setState({ loadingAssets: false });
      });
    }

    if (!this.props.users.length) {
      this.setState({ loadingUsers: true });

      this.props.firebase.users().on('value', (snapshot) => {
        this.props.onSetUsers(snapshot.val());

        this.setState({ loadingUsers: false });
      });
    }
  }

  exportToCSV = (assets, region, uid) => {
    const promise = new Promise(async (resolve) => {
      // Use web worker to process the data

      const csvData = await instance.processQualifiedForCSV(assets);
      // const csvData = await instance.processDataForCSV(
      //   this.props.assets,
      //   this.props.users
      // );
      this.setState({
        csvData: csvData,
        csvName: `${region} - ${this.props.users[uid].username}.csv`,
      });
      resolve();
    });
    promise.then(() => {
      this.csvLink.current.link.click();
    });
    return;
  };

  componentWillUnmount() {
    this.props.firebase.getAllAssets().off();
    this.props.firebase.users().off();
  }

  render() {
    const { assets, authUser } = this.props;
    const { loadingAssets, loadingUsers } = this.state;

    return (
      <Container>
        <h1>Assets</h1>
        {loadingAssets && loadingUsers && (
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Loading data...</span>
          </Spinner>
        )}
        <Row>
          {/* <Button
            onClick={() =>
              this.props.firebase.database().update({
                assetProperties: { props: 'Asd', props2: 'asd' },
              })
            }
          >
            Create in db
          </Button>
          <Button onClick={this.exportToCSV}>Export Qualified Companies</Button> */}

          <CSVLink
            data={this.state.csvData}
            header={this.state.headers}
            filename={this.state.csvName}
            style={{ display: 'hidden' }}
            ref={this.csvLink}
            target="_blank"
          />
        </Row>
        {assets[authUser.uid]
          ? Object.keys(assets[authUser.uid]).map((key) => {
              return (
                <Card
                  className="bg-dark"
                  key={key}
                  style={{ marginTop: '35px' }}
                >
                  <Card.Header>
                    <h2>{key}</h2>
                  </Card.Header>
                  <Card.Body>
                    {Object.keys(assets[authUser.uid][key]).map((childKey) => {
                      const groupedAssetsByCompany = groupBy(
                        assets[authUser.uid][key][childKey].features,
                        'properties'
                      );
                      const sortedGroupedAssets = sortObject(
                        groupedAssetsByCompany
                      );
                      return (
                        <Accordion key={childKey}>
                          <Card className="bg-dark">
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                              {capitalize(childKey)} (
                              {
                                assets[authUser.uid][key][childKey].features
                                  .length
                              }
                              )
                              {childKey === 'qualified' ? (
                                <Button
                                  onClick={() =>
                                    this.exportToCSV(
                                      sortedGroupedAssets,
                                      key,
                                      authUser.uid
                                    )
                                  }
                                  className="float-right"
                                  size="sm"
                                >
                                  Export
                                </Button>
                              ) : null}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                {Object.entries(sortedGroupedAssets).map(
                                  ([company, value], index) => {
                                    return (
                                      <CompanyTable
                                        key={index}
                                        name={company}
                                        data={value}
                                      />
                                    );
                                  }
                                )}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      );
                    })}
                  </Card.Body>
                </Card>
              );
            })
          : !loadingAssets && <div>Seems there are no assets to show.</div>}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assets: state.assetsState.assets || {},
    authUser: state.sessionState.authUser,
    users: state.userState.users || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadAssets: () => dispatch(startLoadAssets(null, null)),
    onSetUsers: (users) => dispatch({ type: 'USERS_SET', users }),
  };
};

const condition = (authUser) => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps)
)(Assets);
