import * as actionTypes from './actionTypes';
import Firebase from '../../components/Firebase/Firebase';

export const startLoadProjects = () => {
  return (dispatch) => {
    Firebase.projects().on('value', (snapshot) => {
      // snapshot.forEach((childSnapshot) => {
      //   const key = childSnapshot.key;
      //   const data = childSnapshot.val();
      //   if (map !== 'undefined' && map.getLayer(key)) {
      //     map.getSource(key).setData(data);
      //   } else {
      //     map.addSource(key, {
      //       type: 'geojson',
      //       data: data,
      //       tolerance: 1,
      //     });

      //     map.addLayer({
      //       id: key,
      //       type: 'fill',
      //       source: key,
      //       paint: {
      //         'fill-color': 'rgba(18, 243, 81, 0.5)',
      //         'fill-outline-color': 'rgba(18, 243, 81, 1)',
      //       },
      //     });
      //   }
      // });

      const data1 = {};

      Object.assign(data1, snapshot.val());

      return dispatch(saveLoadProjects(data1, false));
    });
  };
};

export const saveLoadProjects = (projects, loadingFlag) => {
  return {
    type: actionTypes.LOAD_PROJECTS,
    projects: projects,
    loadingFlag: loadingFlag,
  };
};

export const startLoadAssets = () => {
  return (dispatch) => {
    Firebase.getAllAssets().on('value', (snapshot) => {
      //   if (map !== null && user !== null) {
      //     snapshot.forEach((childSnapshot) => {
      //       const keyUser = childSnapshot.key;
      //       const data = childSnapshot.val();
      //       if (keyUser === user) {
      //         Object.keys(data).map((key) => {
      //           Object.keys(data[key]).map((type) => {
      //             const layerName =
      //               childSnapshot.key.toString() +
      //               '/' +
      //               key.toString() +
      //               '/' +
      //               type.toString();
      //             if (type === 'qualified') {
      //               if (map.getLayer(layerName)) {
      //                 map.getSource(layerName).setData(data[key].qualified);
      //               } else {
      //                 map.addSource(layerName, {
      //                   type: 'geojson',
      //                   data: data[key].qualified,
      //                   tolerance: 1,
      //                 });
      //                 map.addLayer({
      //                   id: layerName,
      //                   type: 'fill',
      //                   source: layerName,
      //                   paint: {
      //                     'fill-color': 'rgba(0, 0, 255, 0.5)',
      //                     'fill-outline-color': 'rgba(0, 0, 255, 1)',
      //                   },
      //                 });
      //               }
      //             } else if (type === 'contacts') {
      //               if (map.getLayer(layerName)) {
      //                 map.getSource(layerName).setData(data[key].contacts);
      //               } else {
      //                 map.addSource(layerName, {
      //                   type: 'geojson',
      //                   data: data[key].contacts,
      //                   tolerance: 1,
      //                 });
      //                 map.addLayer({
      //                   id: layerName,
      //                   type: 'fill',
      //                   source: layerName,
      //                   paint: {
      //                     'fill-color': 'rgba(255, 255, 0, 0.5)',
      //                     'fill-outline-color': 'rgba(255, 255, 0, 1)',
      //                   },
      //                 });
      //               }
      //             } else if (type === 'disqualified') {
      //               if (map.getLayer(layerName)) {
      //                 map.getSource(layerName).setData(data[key].disqualified);
      //               } else {
      //                 map.addSource(layerName, {
      //                   type: 'geojson',
      //                   data: data[key].disqualified,
      //                   tolerance: 1,
      //                 });
      //                 map.addLayer({
      //                   id: layerName,
      //                   type: 'fill',
      //                   source: layerName,
      //                   paint: {
      //                     'fill-color': 'rgba(255, 0, 68, 0.5)',
      //                     'fill-outline-color': 'rgba(255, 0, 68, 1)',
      //                   },
      //                 });
      //               }
      //             } else {
      //               if (map.getLayer(layerName)) {
      //                 map.getSource(layerName).setData(data[key].blacklist);
      //               } else {
      //                 map.addSource(layerName, {
      //                   type: 'geojson',
      //                   data: data[key].blacklist,
      //                   tolerance: 1,
      //                 });
      //                 map.addLayer({
      //                   id: layerName,
      //                   type: 'fill',
      //                   source: layerName,
      //                   paint: {
      //                     'fill-color': 'rgba(0, 0, 0, 0.5)',
      //                     'fill-outline-color': 'rgba(0, 0, 0, 1)',
      //                   },
      //                 });
      //               }
      //             }
      //           });
      //         });
      //       } else {
      //         Object.keys(data).map((key) => {
      //           Object.keys(data[key]).map((type) => {
      //             const layerName =
      //               childSnapshot.key.toString() +
      //               '/' +
      //               key.toString() +
      //               '/' +
      //               type.toString();
      //             if (type === 'disqualified') {
      //               if (map.getLayer(layerName)) {
      //                 map.getSource(layerName).setData(data[key].disqualified);
      //               } else {
      //                 map.addSource(layerName, {
      //                   type: 'geojson',
      //                   data: data[key].disqualified,
      //                   tolerance: 1,
      //                 });
      //                 map.addLayer({
      //                   id: layerName,
      //                   type: 'fill',
      //                   source: layerName,
      //                   paint: {
      //                     'fill-color': 'rgba(255, 0, 68, 0.5)',
      //                     'fill-outline-color': 'rgba(255, 0, 68, 1)',
      //                   },
      //                 });
      //               }
      //             } else if (type === 'blacklist') {
      //               if (map.getLayer(layerName)) {
      //                 map.getSource(layerName).setData(data[key][type]);
      //               } else {
      //                 map.addSource(layerName, {
      //                   type: 'geojson',
      //                   data: data[key][type],
      //                   tolerance: 1,
      //                 });
      //                 map.addLayer({
      //                   id: layerName,
      //                   type: 'fill',
      //                   source: layerName,
      //                   paint: {
      //                     'fill-color': 'rgba(0, 0, 0, 0.5)',
      //                     'fill-outline-color': 'rgba(0, 0, 0, 1)',
      //                   },
      //                 });
      //               }
      //             } else {
      //               if (map.getLayer(layerName)) {
      //                 map.getSource(layerName).setData(data[key][type]);
      //               } else {
      //                 map.addSource(layerName, {
      //                   type: 'geojson',
      //                   data: data[key][type],
      //                   tolerance: 1,
      //                 });
      //                 map.addLayer({
      //                   id: layerName,
      //                   type: 'fill',
      //                   source: layerName,
      //                   paint: {
      //                     'fill-color': 'rgba(255, 161, 0, 0.5)',
      //                     'fill-outline-color': 'rgba(255, 161, 0, 1)',
      //                   },
      //                 });
      //               }
      //             }
      //           });
      //         });
      //       }
      //     });
      //   }
      const data1 = {};

      Object.assign(data1, snapshot.val());

      return dispatch(saveLoadAssets(data1, false));
    });
  };
};

export const saveLoadAssets = (data, loadingFlag) => {
  return {
    type: actionTypes.LOAD_ASSETS,
    assets: data,
    loadingFlag: loadingFlag,
  };
};
