import React from 'react';
import {
  InputGroup,
  FormControl,
  Tabs,
  Tab,
  Card,
  Accordion,
  Container,
  Spinner,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../../Firebase';
import { saveLoadAssets } from '../../../store/actions';
import List from './ItemList/ItemList';

const groupBy = (arr, properties) => {
  if (arr !== ' undefined') {
    return arr.reduce(function (memo, x) {
      if (!memo[x[properties].county]) {
        memo[x[properties].county] = [];
      }
      memo[x[properties].county].push(x);
      return memo;
    }, {});
  }
};

const sortObject = (o) =>
  Object.keys(o)
    .sort()
    .reduce((r, k) => ((r[k] = o[k]), r), {});

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

class Drawer extends React.PureComponent {
  state = {
    filterUprocessed: null,
    filterProcessed: null,
    filterProcessedTeam: null,
  };

  componentDidMount() {
    if (!this.props.assets.length) {
      this.setState({ loading: true });

      this.props.firebase.getAllAssets().on('value', (snapshot) => {
        this.props.loadAssets(snapshot.val());

        this.setState({ loading: false });
      });
    }
  }

  componentWillUnmount() {
    this.props.firebase.getAllAssets().off();
  }

  filterAssetsUnprocessed = (event) => {
    let companyName = event.target.value;
    this.setState({ filterUprocessed: companyName });
  };

  filterAssetsProcessed = (event) => {
    let companyName = event.target.value;
    this.setState({ filterProcessed: companyName });
  };

  filterAssetsProcessedTeam = (event) => {
    let companyName = event.target.value;
    this.setState({ filterProcessedTeam: companyName });
  };

  render() {
    return (
      <Container fluid>
        <Tabs variant="pills" defaultActiveKey="unprocessed">
          <Tab eventKey="unprocessed" title="Not processed">
            <InputGroup className="mb-3" style={{ margin: '5px' }}>
              <FormControl
                onChange={(e) => this.filterAssetsUnprocessed(e)}
                placeholder="Filter assets by company name"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
              />
            </InputGroup>
            {this.props.projects === null
              ? null
              : Object.entries(this.props.projects).map(([project, value]) => {
                  const items = value.features.filter((asset) => {
                    if (this.state.filterUprocessed == null) {
                      return asset;
                    } else if (
                      asset.properties.proprietorName
                        .toLowerCase()
                        .includes(this.state.filterUprocessed.toLowerCase())
                    ) {
                      return asset;
                    }
                  });

                  if (items.length === 0) return null;

                  const groupedData = groupBy(items, 'properties');

                  const sortedGroupedData = sortObject(groupedData);

                  return (
                    <Accordion key={project} style={{ paddingTop: '10px' }}>
                      <Card className="bg-dark">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey={project.toString()}
                        >
                          {project} ({items.length})
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={project.toString()}>
                          <Card.Body>
                            {Object.entries(sortedGroupedData).map(
                              ([county, data], index) => {
                                data.sort((a, b) => {
                                  if (
                                    a.properties['proprietorName'] ===
                                    b.properties['proprietorName']
                                  ) {
                                    return (
                                      b.properties['roofSize'] -
                                      b.properties['roofSize']
                                    );
                                  }
                                  return a.properties['proprietorName'] >
                                    b.properties['proprietorName']
                                    ? 1
                                    : -1;
                                });

                                //console.log('county', county);
                                //console.log('data', data);
                                return (
                                  <Accordion key={county}>
                                    <Card className="bg-dark">
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey={county.toString()}
                                      >
                                        {county} ({data.length})
                                      </Accordion.Toggle>
                                      <Accordion.Collapse
                                        eventKey={county.toString()}
                                      >
                                        <Card.Body>
                                          <List
                                            arrayData={data}
                                            clickFunc={this.props.click}
                                          />
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  </Accordion>
                                );
                              }
                            )}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  );
                })}
          </Tab>
          <Tab eventKey="processed" title="Processed">
            <InputGroup className="mb-3" style={{ margin: '5px' }}>
              <FormControl
                onChange={(e) => this.filterAssetsProcessed(e)}
                placeholder="Filter assets by company name"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
              />
            </InputGroup>
            {this.props.assets[this.props.authUser.uid] ? (
              Object.keys(this.props.assets[this.props.authUser.uid]).map(
                (key) => {
                  let items = null;

                  items = Object.keys(
                    this.props.assets[this.props.authUser.uid][key]
                  ).map((childKey) => {
                    const assets = this.props.assets[this.props.authUser.uid][
                      key
                    ][childKey].features
                      .filter((asset) => {
                        if (this.state.filterProcessed == null) {
                          return asset;
                        } else if (
                          asset.properties.proprietorName
                            .toLowerCase()
                            .includes(this.state.filterProcessed.toLowerCase())
                        ) {
                          return asset;
                        }
                      })
                      .sort((a, b) => {
                        if (
                          a.properties['proprietorName'] ===
                          b.properties['proprietorName']
                        ) {
                          return (
                            b.properties['roofSize'] - b.properties['roofSize']
                          );
                        }
                        return a.properties['proprietorName'] >
                          b.properties['proprietorName']
                          ? 1
                          : -1;
                      });
                    if (assets.length === 0) {
                      return null;
                    } else
                      return (
                        <Accordion key={childKey}>
                          <Card className="bg-dark">
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey={childKey.toString()}
                            >
                              {capitalize(childKey)} ({assets.length})
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={childKey.toString()}>
                              <Card.Body>
                                <List
                                  arrayData={assets}
                                  clickFunc={this.props.click}
                                />
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      );
                  });
                  let itemsToShow = null;
                  if (items[0] !== null) {
                    itemsToShow = (
                      <Accordion key={key} style={{ paddingTop: '10px' }}>
                        <Card className="bg-dark">
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey={key.toString()}
                          >
                            {key}
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={key.toString()}>
                            <Card.Body>{items}</Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    );
                  }
                  return itemsToShow;
                }
              )
            ) : (
              <p>No assets processed yet.</p>
            )}
          </Tab>
          <Tab eventKey="processedByTeam" title="Processed by team">
            <InputGroup className="mb-3" style={{ margin: '5px' }}>
              <FormControl
                onChange={(e) => this.filterAssetsProcessedTeam(e)}
                placeholder="Filter assets by company name"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
              />
            </InputGroup>
            {this.props.assets ? (
              Object.keys(this.props.assets).map((userKey) => {
                if (userKey !== this.props.authUser.uid) {
                  return (
                    <div style={{ paddingTop: '10px' }} key={userKey}>
                      <h3>
                        {this.props.users && this.props.users[userKey]
                          ? this.props.users[userKey].username
                          : null}
                      </h3>
                      {this.props.assets[userKey]
                        ? Object.keys(this.props.assets[userKey]).map((key) => {
                            let items = null;

                            items = Object.keys(
                              this.props.assets[userKey][key]
                            ).map((childKey) => {
                              const assets = this.props.assets[userKey][key][
                                childKey
                              ].features
                                .filter((asset) => {
                                  if (this.state.filterProcessedTeam == null) {
                                    return asset;
                                  } else if (
                                    asset.properties.proprietorName
                                      .toLowerCase()
                                      .includes(
                                        this.state.filterProcessedTeam.toLowerCase()
                                      )
                                  ) {
                                    return asset;
                                  }
                                })
                                .sort((a, b) => {
                                  if (
                                    a.properties['proprietorName'] ===
                                    b.properties['proprietorName']
                                  ) {
                                    return (
                                      b.properties['roofSize'] -
                                      b.properties['roofSize']
                                    );
                                  }
                                  return a.properties['proprietorName'] >
                                    b.properties['proprietorName']
                                    ? 1
                                    : -1;
                                });
                              if (assets.length === 0) {
                                return null;
                              } else
                                return (
                                  <Accordion key={childKey}>
                                    <Card className="bg-dark">
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey={childKey.toString()}
                                      >
                                        {capitalize(childKey)} ({assets.length})
                                      </Accordion.Toggle>
                                      <Accordion.Collapse
                                        eventKey={childKey.toString()}
                                      >
                                        <Card.Body>
                                          <List
                                            arrayData={assets}
                                            clickFunc={this.props.click}
                                          />
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  </Accordion>
                                );
                            });
                            let itemsToShow = null;
                            if (items[0] !== null) {
                              itemsToShow = (
                                <Accordion
                                  key={key}
                                  style={{ paddingTop: '10px' }}
                                >
                                  <Card className="bg-dark">
                                    <Accordion.Toggle
                                      as={Card.Header}
                                      eventKey={key.toString()}
                                    >
                                      {key}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse
                                      eventKey={key.toString()}
                                    >
                                      <Card.Body>{items}</Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              );
                            }
                            return itemsToShow;
                          })
                        : null}
                    </div>
                  );
                }
              })
            ) : (
              <Spinner animation="border" role="status" variant="primary">
                <span className="sr-only">Loading data...</span>
              </Spinner>
            )}
          </Tab>
        </Tabs>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assets: state.assetsState.assets || {},
    users: state.userState.users || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAssets: (data) => dispatch(saveLoadAssets(data, false)),
  };
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Drawer);
