import {
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Tabs,
  InputGroup,
  FormControl,
  Tab,
  Row,
  Col,
  ListGroup,
} from 'react-bootstrap';
import React, { useState } from 'react';
import StaticAssetMap from '../shared/StaticAssetMap';

function numberWithCommas(x) {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  else return x;
}

const DataTabs = (props) => {
  const mapLink = `https://www.google.com/maps/@${props.json.lat},${props.json.long},400m/data=!3m1!1e3`;
  let hideDisqualifyButton = false;
  let hideQualifyButton = false;

  const [disqualifyReason, setDisqualifyReason] = useState('Select Reason');
  const handleSelect = (e) => {
    setDisqualifyReason(e);
  };

  if (props.assetData.projectName.split('/')[2] === 'qualified') {
    hideQualifyButton = true;
  } else if (props.assetData.projectName.split('/')[2] === 'disqualified') {
    hideQualifyButton = true;
    hideDisqualifyButton = true;
  }

  //https://www.google.com/maps/@38.9517374,-9.3982374,400m/data=!3m1!1e3

  return (
    <Tabs defaultActiveKey="manageAsset" variant="pills">
      <Tab
        eventKey="manageAsset"
        title="Asset"
        style={{ height: '400px', overflowY: 'auto' }}
      >
        <Row style={{ width: '95%' }}>
          <Col sm={9}>
            <StaticAssetMap assetData={props.assetData} />
            <Button
              href={mapLink}
              target="_blank"
              style={{ marginTop: '15px', marginBottom: '15px' }}
            >
              View on Google Map
            </Button>
          </Col>
          <Col sm={3}>
            <p style={{ margin: '5px' }}>Manage Asset</p>
            {props.hideButtons ? (
              <span style={{ margin: '5px' }}>
                Asset owned by another user.
              </span>
            ) : (
              <Row>
                {hideQualifyButton ? null : (
                  <Button
                    variant="success"
                    onClick={props.qualify}
                    style={{ margin: '15px' }}
                    disabled={props.disableAssetButtons}
                  >
                    Qualify
                  </Button>
                )}
                {hideDisqualifyButton ? null : (
                  <ButtonGroup style={{ margin: '15px' }}>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        disqualifyReason !== 'Select Reason' ? (
                          <span></span>
                        ) : (
                          <Tooltip>
                            Please select a <strong>reason</strong> to
                            disqualify.
                          </Tooltip>
                        )
                      }
                    >
                      <Button
                        variant="danger"
                        onClick={
                          disqualifyReason !== 'Select Reason'
                            ? () => props.disqualify(disqualifyReason)
                            : null
                        }
                        disabled={props.disableAssetButtons}
                      >
                        Disqualify
                      </Button>
                    </OverlayTrigger>
                    <DropdownButton
                      variant="danger"
                      as={ButtonGroup}
                      title={disqualifyReason}
                      id="bg-nested-dropdown"
                      onSelect={handleSelect}
                    >
                      <Dropdown.Item eventKey="Roof not suitable">
                        Roof not suitable
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Leased">Leased</Dropdown.Item>
                      <Dropdown.Item eventKey="Asset already has solar">
                        Asset already has solar
                      </Dropdown.Item>
                    </DropdownButton>
                  </ButtonGroup>
                )}
                <Button
                  variant="light"
                  onClick={props.blacklist}
                  style={{ margin: '15px' }}
                  disabled={props.disableAssetButtons}
                >
                  Blacklist Company
                </Button>
              </Row>
            )}
          </Col>
        </Row>
      </Tab>
      <Tab
        eventKey="companyInfo"
        title="Company Information"
        style={{ height: '400px', overflowY: 'auto' }}
      >
        <ListGroup>
          <ListGroup>
            {props.data.proprietorName ? (
              <ListGroup.Item className="bg-dark">
                <strong>Company name:</strong> {props.data.proprietorName}
              </ListGroup.Item>
            ) : null}
            <ListGroup.Item className="bg-dark">
              <strong>Industry keywords: </strong> {props.json.keywords}
            </ListGroup.Item>
            <ListGroup.Item className="bg-dark">
              <strong>Estimated Capacity (kWp): </strong>{' '}
              {numberWithCommas(
                parseInt((parseInt(props.json.roofSize, 10) * 140) / 1000, 10)
              )}
            </ListGroup.Item>
            <ListGroup.Item className="bg-dark">
              <strong>Shareholders: </strong> {props.json.shareholdersNames}
            </ListGroup.Item>
            <ListGroup.Item className="bg-dark">
              <strong>Credit Risk: </strong> {props.json.creditRisk}
            </ListGroup.Item>
            <ListGroup.Item className="bg-dark">
              <strong>Charges: </strong> {props.json.charges}
            </ListGroup.Item>
            {props.json.website !== '0' && props.json.website !== '#N/A' ? (
              <ListGroup.Item className="bg-dark">
                <strong>Website: </strong>{' '}
                <a
                  href={props.json.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {props.json.website}
                </a>
              </ListGroup.Item>
            ) : null}
          </ListGroup>
        </ListGroup>
      </Tab>
      <Tab
        eventKey="financialInfo"
        title="Financial Information"
        style={{ height: '400px', overflowY: 'auto' }}
      >
        <ListGroup>
          <ListGroup.Item className="bg-dark">
            <strong>EBITDA: </strong> £ {numberWithCommas(props.json.ebitda)}
          </ListGroup.Item>
          <ListGroup.Item className="bg-dark">
            <strong>Cost of Sales: </strong> £{' '}
            {numberWithCommas(props.json.costOfSales)}
          </ListGroup.Item>
          <ListGroup.Item className="bg-dark">
            <strong>Capital Employed: </strong> £{' '}
            {numberWithCommas(props.json.capitalEmployed)}{' '}
          </ListGroup.Item>
          <ListGroup.Item className="bg-dark">
            <strong>Cash: </strong> £ {numberWithCommas(props.json.cash)}
          </ListGroup.Item>
          <ListGroup.Item className="bg-dark">
            <strong>Current Assets: </strong> £{' '}
            {numberWithCommas(props.json.currentAssets)}
          </ListGroup.Item>
          <ListGroup.Item className="bg-dark">
            <strong>Current Liabilities: </strong> £{' '}
            {numberWithCommas(props.json.currentLiabilities)}
          </ListGroup.Item>
          <ListGroup.Item className="bg-dark">
            <strong>Net Assets:</strong> £{' '}
            {numberWithCommas(props.json.netAssets)}
          </ListGroup.Item>
        </ListGroup>
      </Tab>
      <Tab
        eventKey="freeholderInfo"
        title="Freeholder Information"
        style={{ height: '400px', overflowY: 'auto' }}
      >
        <ListGroup>
          {props.data.propertyAddress ? (
            <ListGroup.Item className="bg-dark">
              <strong>Address:</strong> {props.data.propertyAddress}
            </ListGroup.Item>
          ) : null}
          {props.data.proprietorName ? (
            <ListGroup.Item className="bg-dark">
              <strong>Proprietor name:</strong> {props.data.proprietorName}
            </ListGroup.Item>
          ) : null}
          {props.data.proprietorshipCategory ? (
            <ListGroup.Item className="bg-dark">
              <strong>Proprietorship category:</strong>{' '}
              {props.data.proprietorshipCategory}
            </ListGroup.Item>
          ) : null}
          {props.data.companyRegistrationNumber ? (
            <ListGroup.Item className="bg-dark">
              <strong>Company registration number:</strong>{' '}
              {props.data.companyRegistrationNumber}
            </ListGroup.Item>
          ) : null}
          {props.data.roofSize ? (
            <ListGroup.Item className="bg-dark">
              <strong>Roof size:</strong> {props.data.roofSize}
            </ListGroup.Item>
          ) : null}
          {props.data.region ? (
            <ListGroup.Item className="bg-dark">
              <strong>Region:</strong> {props.data.region}
            </ListGroup.Item>
          ) : null}
          {props.data.county ? (
            <ListGroup.Item className="bg-dark">
              <strong>County:</strong> {props.data.county}
            </ListGroup.Item>
          ) : null}
          {props.data.district ? (
            <ListGroup.Item className="bg-dark">
              <strong>District:</strong> {props.data.district}
            </ListGroup.Item>
          ) : null}
          {props.data.dateProprietorAdded ? (
            <ListGroup.Item className="bg-dark">
              <strong>Date proprietor added:</strong>{' '}
              {props.data.dateProprietorAdded}
            </ListGroup.Item>
          ) : null}
          {props.data.multipleAddressIndicator ? (
            <ListGroup.Item className="bg-dark">
              <strong>Multiple address Indicator:</strong>{' '}
              {props.data.multipleAddressIndicator}
            </ListGroup.Item>
          ) : null}
          {props.data.postcode ? (
            <ListGroup.Item className="bg-dark">
              <strong>Postcode:</strong> {props.data.postcode}
            </ListGroup.Item>
          ) : null}
          {props.data.pricePaid ? (
            <ListGroup.Item className="bg-dark">
              <strong>Price paid:</strong> {props.data.pricePaid}
            </ListGroup.Item>
          ) : null}
        </ListGroup>
      </Tab>
      {props.data.leaseholds ? (
        <Tab
          eventKey="leaseholderInfo"
          title="Leaseholder Information"
          style={{ height: '400px', overflowY: 'auto' }}
        >
          {props.data.leaseholds ? (
            <ListGroup.Item className="bg-dark">
              <strong>Address:</strong> {props.data.leaseholds[0].address}
            </ListGroup.Item>
          ) : null}
          {props.data.leaseholds ? (
            <ListGroup.Item className="bg-dark">
              <strong>Proprietor:</strong> {props.data.leaseholds[0].proprietor}
            </ListGroup.Item>
          ) : null}
          {props.data.leaseholds ? (
            <ListGroup.Item className="bg-dark">
              <strong>Company registration number:</strong>{' '}
              {props.data.leaseholds[0].companyNo}
            </ListGroup.Item>
          ) : null}
          {props.data.leaseholds ? (
            <ListGroup.Item className="bg-dark">
              <strong>Date proprietor added:</strong>{' '}
              {props.data.leaseholds[0].datePropr}
            </ListGroup.Item>
          ) : null}
          {props.data.leaseholds ? (
            <ListGroup.Item className="bg-dark">
              <strong>Region:</strong> {props.data.leaseholds[0].region}
            </ListGroup.Item>
          ) : null}
          {props.data.leaseholds ? (
            <ListGroup.Item className="bg-dark">
              <strong>County:</strong> {props.data.leaseholds[0].county}
            </ListGroup.Item>
          ) : null}
          {props.data.leaseholds ? (
            <ListGroup.Item className="bg-dark">
              <strong>District:</strong> {props.data.leaseholds[0].district}
            </ListGroup.Item>
          ) : null}
        </Tab>
      ) : null}

      <Tab
        eventKey="notes"
        title="Notes"
        style={{ height: '400px', overflowY: 'auto' }}
      >
        <InputGroup>
          <FormControl
            as="textarea"
            style={{ minHeight: '400px', height: '400px' }}
            value={props.userNotes}
            onChange={props.userNotesOnChange}
          />
        </InputGroup>
      </Tab>
    </Tabs>
  );
  {
    /* <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link0">
        <Row>
          <Col sm={width < 800 ? 5 : 3}>
            <ListGroup>
              <ListGroup.Item action href="#link0">
                Company Information
              </ListGroup.Item>
              <ListGroup.Item action href="#link1">
                Financial Information
              </ListGroup.Item>
              <ListGroup.Item action href="#link2">
                Freeholder Details
              </ListGroup.Item>
              {props.data.leaseholds ? (
                <ListGroup.Item action href="#link3">
                  Leaseholder Details
                </ListGroup.Item>
              ) : null}
              <ListGroup.Item action href="#link4">
                View Property
              </ListGroup.Item>
              <ListGroup.Item action href="#link5">
                Notes
              </ListGroup.Item>
              {props.hideButtons ? null : (
                <ListGroup.Item action href="#link6">
                  Manage asset
                </ListGroup.Item>
              )}
            </ListGroup>
          </Col>
          <Col sm={width < 800 ? 7 : 9}>
            <Tab.Content>
              <Tab.Pane
                eventKey="#link0"
                style={{ height: '400px', overflowY: 'auto' }}
              >
                <ListGroup>
                  <ListGroup>
                    {props.data.proprietorName ? (
                      <ListGroup.Item className="bg-dark">
                        <strong>Company name:</strong>{' '}
                        {props.data.proprietorName}
                      </ListGroup.Item>
                    ) : null}
                    <ListGroup.Item className="bg-dark">
                      <strong>Business description: </strong>{' '}
                      {props.json.SIC2007Description}
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-dark">
                      <strong>Industry keywords: </strong>{' '}
                      {props.json.industryKeywords}
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-dark">
                      <strong>Estimated Capacity (kWp): </strong>{' '}
                      {props.json.county === 'CITY OF NOTTINGHAM' ||
                      props.json.county === 'CITY OF DERBY' ||
                      props.json.county === 'RUTLAND' ||
                      props.json.county === 'DERBYSHIRE' ||
                      props.json.county === 'LEICESTERSHIRE' ||
                      props.json.county === 'NOTTINGHAMSHIRE' ||
                      props.json.county === 'LINCOLNSHIRE' ||
                      props.json.county === 'WEST MIDLANDS' ||
                      props.json.county === 'STAFFORDSHIRE'
                        ? numberWithCommas(
                            parseInt(
                              (parseInt(props.json.roofSize, 10) * 140) / 1000,
                              10
                            )
                          )
                        : numberWithCommas(
                            parseInt(
                              (parseInt(props.json.roofSize, 10) * 140) / 1000,
                              10
                            )
                          )}
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-dark">
                      <strong>Shareholders: </strong>{' '}
                      {props.json.shareholdersNames}
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-dark">
                      <strong>Credit Risk: </strong> {props.json.creditRisk}
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-dark">
                      <strong>Charges: </strong> {props.json.charges}
                    </ListGroup.Item>
                    {props.json.County !== 'STAFFORDSHIRE' &&
                    props.json.County !== 'WEST MIDLANDS' ? (
                      <ListGroup.Item className="bg-dark">
                        <strong>Dormant Company: </strong>{' '}
                        {props.json.dormantCompany}
                      </ListGroup.Item>
                    ) : null}
                    {props.json.webAddress !== '0' &&
                    props.json.webAddress !== '#N/A' ? (
                      <ListGroup.Item className="bg-dark">
                        <strong>Website: </strong>{' '}
                        <a
                          href={props.json.webAddress}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {props.json.webAddress}
                        </a>
                      </ListGroup.Item>
                    ) : null}
                  </ListGroup>
                </ListGroup>
              </Tab.Pane>
              <Tab.Pane
                eventKey="#link1"
                style={{ height: '400px', overflowY: 'auto' }}
              >
                <ListGroup>
                  <ListGroup.Item className="bg-dark">
                    <strong>EBITDA: </strong> £{' '}
                    {numberWithCommas(props.json.EBITDA)}
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark">
                    <strong>Cost of Sales: </strong> £{' '}
                    {numberWithCommas(props.json.costOfSales)}
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark">
                    <strong>Capital Employed: </strong> £{' '}
                    {numberWithCommas(props.json.capitalEmployed)}{' '}
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark">
                    <strong>Cash: </strong> £{' '}
                    {numberWithCommas(props.json.cash)}
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark">
                    <strong>Current Assets: </strong> £{' '}
                    {numberWithCommas(props.json.cash)}
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark">
                    <strong>Current Liabilities: </strong> £{' '}
                    {numberWithCommas(props.json.currentLiabilities)}
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark">
                    <strong>Net Assets:</strong> £{' '}
                    {numberWithCommas(props.json.netAssets)}
                  </ListGroup.Item>
                </ListGroup>
              </Tab.Pane>
              <Tab.Pane
                eventKey="#link2"
                style={{ height: '400px', overflowY: 'auto' }}
              >
                <ListGroup>
                  {props.data.propertyAddress ? (
                    <ListGroup.Item className="bg-dark">
                      <strong>Address:</strong> {props.data.propertyAddress}
                    </ListGroup.Item>
                  ) : null}
                  {props.data.proprietorName ? (
                    <ListGroup.Item className="bg-dark">
                      <strong>Proprietor name:</strong>{' '}
                      {props.data.proprietorName}
                    </ListGroup.Item>
                  ) : null}
                  {props.data.proprietorshipCategory ? (
                    <ListGroup.Item className="bg-dark">
                      <strong>Proprietorship category:</strong>{' '}
                      {props.data.proprietorshipCategory}
                    </ListGroup.Item>
                  ) : null}
                  {props.data.companyRegistrationNumber ? (
                    <ListGroup.Item className="bg-dark">
                      <strong>Company registration number:</strong>{' '}
                      {props.data.companyRegistrationNumber}
                    </ListGroup.Item>
                  ) : null}
                  {props.data.roofSize ? (
                    <ListGroup.Item className="bg-dark">
                      <strong>Roof size:</strong> {props.data.roofSize}
                    </ListGroup.Item>
                  ) : null}
                  {props.data.region ? (
                    <ListGroup.Item className="bg-dark">
                      <strong>Region:</strong> {props.data.region}
                    </ListGroup.Item>
                  ) : null}
                  {props.data.county ? (
                    <ListGroup.Item className="bg-dark">
                      <strong>County:</strong> {props.data.county}
                    </ListGroup.Item>
                  ) : null}
                  {props.data.district ? (
                    <ListGroup.Item className="bg-dark">
                      <strong>District:</strong> {props.data.district}
                    </ListGroup.Item>
                  ) : null}
                  {props.data.dateProprietorAdded ? (
                    <ListGroup.Item className="bg-dark">
                      <strong>Date proprietor added:</strong>{' '}
                      {props.data.dateProprietorAdded}
                    </ListGroup.Item>
                  ) : null}
                  {props.data.multipleAddressIndicator ? (
                    <ListGroup.Item className="bg-dark">
                      <strong>Multiple address Indicator:</strong>{' '}
                      {props.data.multipleAddressIndicator}
                    </ListGroup.Item>
                  ) : null}
                  {props.data.postcode ? (
                    <ListGroup.Item className="bg-dark">
                      <strong>Postcode:</strong> {props.data.postcode}
                    </ListGroup.Item>
                  ) : null}
                  {props.data.pricePaid ? (
                    <ListGroup.Item className="bg-dark">
                      <strong>Price paid:</strong> {props.data.pricePaid}
                    </ListGroup.Item>
                  ) : null}
                </ListGroup>
              </Tab.Pane>
              <Tab.Pane
                eventKey="#link3"
                style={{ height: '400px', overflowY: 'auto' }}
              >
                {props.data.leaseholds ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>Address:</strong> {props.data.leaseholds[0].address}
                  </ListGroup.Item>
                ) : null}
                {props.data.leaseholds ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>Proprietor:</strong>{' '}
                    {props.data.leaseholds[0].proprietor}
                  </ListGroup.Item>
                ) : null}
                {props.data.leaseholds ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>Company registration number:</strong>{' '}
                    {props.data.leaseholds[0].companyNo}
                  </ListGroup.Item>
                ) : null}
                {props.data.leaseholds ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>Date proprietor added:</strong>{' '}
                    {props.data.leaseholds[0].datePropr}
                  </ListGroup.Item>
                ) : null}
                {props.data.leaseholds ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>Region:</strong> {props.data.leaseholds[0].region}
                  </ListGroup.Item>
                ) : null}
                {props.data.leaseholds ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>County:</strong> {props.data.leaseholds[0].county}
                  </ListGroup.Item>
                ) : null}
                {props.data.leaseholds ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>District:</strong>{' '}
                    {props.data.leaseholds[0].district}
                  </ListGroup.Item>
                ) : null}
              </Tab.Pane>
              <Tab.Pane
                eventKey="#link4"
                style={{ height: '400px', overflowY: 'auto' }}
              >
                <Row style={{ width: '95%' }}>
                  <Col sm={7}>
                    <StaticAssetMap
                      lat={props.json.lat}
                      long={props.json.long}
                      assetData={props.assetData}
                    />
                  </Col>
                  <Col sm={4}>
                    <Button
                      href={mapLink}
                      target="_blank"
                      style={{ margin: '5px' }}
                    >
                      View on Google Map
                    </Button>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane
                eventKey="#link5"
                style={{ height: '400px', overflowY: 'auto' }}
              >
                <InputGroup>
                  <FormControl
                    as="textarea"
                    style={{ minHeight: '400px', height: '400px' }}
                    value={props.userNotes}
                    onChange={props.userNotesOnChange}
                  />
                </InputGroup>
              </Tab.Pane>
              {props.hideButtons ? null : (
                <Tab.Pane
                  eventKey="#link6"
                  style={{ height: '400px', overflowY: 'auto' }}
                >
                  <Row style={{ width: '80%' }}>
                    {hideQualifyButton ? null : (
                      <Button
                        variant="success"
                        onClick={props.qualify}
                        style={{ margin: '15px' }}
                      >
                        Qualify
                      </Button>
                    )}
                  </Row>
                  <Row style={{ width: '80%' }}>
                    {hideDisqualifyButton ? null : (
                      <Button
                        variant="danger"
                        onClick={props.disqualify}
                        style={{ margin: '15px' }}
                      >
                        Disqualify
                      </Button>
                    )}
                  </Row>
                  <Row style={{ width: '80%' }}>
                    <Button
                      variant="light"
                      onClick={props.blacklist}
                      style={{ margin: '15px' }}
                    >
                      Blacklist
                    </Button>
                  </Row>
                </Tab.Pane>
              )}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </> */
  }
};

export default DataTabs;
