import React, { Component } from 'react';
import { PureComponent } from 'react';
import { ListGroup } from 'react-bootstrap';
import { FixedSizeList as List } from 'react-window';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

class item extends PureComponent {
  render() {
    const item = this.props.data.data[this.props.index];

    return (
      <ListGroup.Item
        style={this.props.style}
        action
        onClick={() =>
          this.props.data.click(
            parseFloat(item.properties.lat, 10),
            parseFloat(item.properties.long, 10),
            item.properties.county
          )
        }
        className="bg-dark"
      >
        <p
          style={{
            fontSize: '11px',
            marginBottom: '5px',
            color: 'white',
          }}
        >
          <strong> Freeholder: </strong>
          {item.properties.proprietorName}{' '}
        </p>
        <p
          style={{
            fontSize: '11px',
            marginBottom: '5px',
            color: 'white',
          }}
        >
          <strong> Capacity (kWp): </strong>
          {numberWithCommas(
            parseInt((parseInt(item.properties.roofSize, 10) * 140) / 1000, 10)
          )}
        </p>
      </ListGroup.Item>
    );
  }
}

class ItemList extends PureComponent {
  render() {
    return (
      <List
        className="List"
        height={400}
        itemCount={this.props.arrayData.length}
        itemSize={100}
        width="100%"
        itemData={{ data: this.props.arrayData, click: this.props.clickFunc }}
      >
        {item}
      </List>
    );
  }
}

export default ItemList;
