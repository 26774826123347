import React, { useState } from 'react';
import ReactMapGL, { NavigationControl, Source, Layer } from 'react-map-gl';

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

const TOKEN =
  'pk.eyJ1IjoiY2Jhc25vIiwiYSI6ImNrN3ozcTFnZzAxd3UzbW85N2FvamlsdXgifQ.GnMuG6cQ3VvJr_4pfe-bOw';

const navStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px',
};

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const StaticAssetMap = (props) => {
  //console.log('PROPS IN MINIMAP', props);
  const [viewport, setViewport] = useState({
    latitude: props.assetData.feature.properties.lat,
    longitude: props.assetData.feature.properties.long,
    zoom: 15,
  });

  let featuresArray = [];
  featuresArray.push(props.assetData.feature);

  const layer = {
    features: featuresArray,
    name: 'viewPropertyLayer',
    type: 'FeatureCollection',
  };

  return (
    <ReactMapGL
      {...viewport}
      width="100%"
      height="330px"
      mapStyle="mapbox://styles/mapbox/satellite-v9"
      mapboxApiAccessToken={TOKEN}
      onViewportChange={setViewport}
      dragPan={true}
      dragRotate={false}
      scrollZoom={true}
      touchZoom={true}
      touchRotate={false}
      keyboard={false}
      doubleClickZoom={false}
      maxZoom={20}
    >
      <div className="nav" style={navStyle}>
        <NavigationControl onViewportChange={setViewport} />
      </div>
      <Source id="viewPropertyLayer" type="geojson" data={layer} />
      <Layer
        id="viewPropertyLayer"
        type="line"
        source="viewPropertyLayer"
        paint={{
          'line-color': 'rgba(0, 123, 255, 1)',
          'line-width': 2,
        }}
      />
    </ReactMapGL>
  );
};

export default StaticAssetMap;
