import React from 'react';
import { Container, Row } from 'react-bootstrap';

import Logo from '../Logo/Logo';

const Landing = () => (
  <Container>
    <Row className="justify-content-md-center">
      <Logo />
    </Row>
  </Container>
);

export default Landing;
