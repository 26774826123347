import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandshake,
  faMap,
  faBuilding,
  faUser,
  faUserCog,
} from '@fortawesome/free-solid-svg-icons';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Logo from '../Logo/LogoNav';

import './Navigation.css';
import './Sidebar.css';

// const Navigation = ({ authUser }) =>
//   authUser ? <NavigationAuth authUser={authUser} /> : <NavigationNonAuth />;

const Navigation = ({ authUser }) =>
  authUser ? <NavigationAuth authUser={authUser} /> : <NavigationNonAuth />;

const NavigationAuth = ({ authUser }) => (
  <Navbar
    bg="dark"
    variant="dark"
    className="col-md-12 d-none d-md-block sidebar"
  >
    <div className="sidebar-sticky"></div>
    <Navbar.Brand
      className="nav-logo"
      href="#landing"
      as={Link}
      to={ROUTES.LANDING}
    >
      <Logo />
    </Navbar.Brand>
    <Nav className="d-md-block">
      <Nav.Link className="nav-button" href="#map" as={Link} to={ROUTES.HOME}>
        <FontAwesomeIcon className="menu-icon" icon={faMap} />
        <span className="nav-text">Map</span>
      </Nav.Link>
      <Nav.Link
        className="nav-button"
        href="#contacts"
        as={Link}
        to={ROUTES.ASSETS}
      >
        <FontAwesomeIcon className="menu-icon" icon={faBuilding} />
        <span className="nav-text">My Assets</span>
      </Nav.Link>
      {/* <Nav.Link
        className="nav-button"
        href="#portofolio"
        as={Link}
        to={ROUTES.DEALS}
      >
        <FontAwesomeIcon className="menu-icon" icon={faHandshake} />
        <span className="nav-text">Deals</span>
      </Nav.Link> */}
      <Nav.Link
        className="nav-button"
        href="#account"
        as={Link}
        to={ROUTES.ACCOUNT}
      >
        <FontAwesomeIcon className="menu-icon" icon={faUser} />
        <span className="nav-text">Account</span>
      </Nav.Link>
      {!!authUser.roles[ROLES.ADMIN] && (
        <Nav.Link
          className="nav-button"
          href="#admin"
          as={Link}
          to={ROUTES.ADMIN}
        >
          <FontAwesomeIcon className="menu-icon" icon={faUserCog} />
          <span className="nav-text">Admin</span>
        </Nav.Link>
      )}
      <Nav.Link className="nav-sign-out-button">
        <SignOutButton />
      </Nav.Link>
    </Nav>
  </Navbar>
);

const NavigationNonAuth = () => (
  <Navbar
    bg="dark"
    variant="dark"
    className="col-md-12 d-none d-md-block sidebar"
  >
    <Navbar.Brand
      className="nav-logo"
      href="#landing"
      as={Link}
      to={ROUTES.LANDING}
    >
      <Logo />
    </Navbar.Brand>
    <Nav className="d-md-block" style={{ paddingLeft: '15px' }}>
      <Nav.Link className="nav-sign-in-button">
        <Button variant="outline-primary" as={Link} to={ROUTES.SIGN_IN}>
          <span style={{ fontSize: '1.2rem' }}>Sign In</span>
        </Button>
      </Nav.Link>
    </Nav>
  </Navbar>
);

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default compose(withRouter, connect(mapStateToProps))(Navigation);
