import React from 'react';
import { connect } from 'react-redux';
import { Card, Button, Badge } from 'react-bootstrap';
import Tabs from './Tabs';

const PointDetails = (props) => {
  // console.log('POINT DETAILS DATA:     ');
  //console.log(props);

  let assetStatus = 'Asset not processed';
  let assetColor = 'success';
  let hideButtons = false;
  //console.log('asdasdasd', props.assetData.projectName);

  if (props.users[props.assetData.projectName.split('/')[0]]) {
    if (props.assetData.projectName.split('/')[2] === 'qualified') {
      assetStatus =
        'Asset qualified by ' +
        props.users[props.assetData.projectName.split('/')[0]].username;
      if (props.assetData.projectName.split('/')[0] === props.authUser.uid) {
        assetColor = 'primary';
      } else {
        assetColor = 'warning';
      }
    } else if (props.assetData.projectName.split('/')[2] === 'contacts') {
      assetStatus =
        'Asset belongs to a company qualified by ' +
        props.users[props.assetData.projectName.split('/')[0]].username;
      assetColor = 'warning';
    } else if (props.assetData.projectName.split('/')[2] === 'disqualified') {
      assetStatus = (
        <div>
          <span>
            Asset disqualified by{' '}
            {props.users[props.assetData.projectName.split('/')[0]].username}
          </span>{' '}
          <Badge pill variant="danger">
            Reason: {props.assetData.feature.properties.disqualificationReason}
          </Badge>
        </div>
      );
      assetColor = 'danger';
    } else if (props.assetData.projectName.split('/')[2] === 'blacklist') {
      assetStatus =
        'Asset and company blacklisted by ' +
        props.users[props.assetData.projectName.split('/')[0]].username;
      assetColor = 'secondary';
    }
  }

  if (
    (props.users.hasOwnProperty(props.assetData.projectName.split('/')[0]) &&
      props.assetData.projectName.split('/')[0] !== props.authUser.uid) ||
    props.assetData.projectName.split('/')[2] === 'blacklist'
  ) {
    hideButtons = true;
  }

  return (
    <Card border={assetColor} className="bg-dark">
      <Card.Body>
        <Card.Title>{assetStatus}</Card.Title>
        <Card.Text>
          <Tabs
            data={props.data}
            fromSavedPoints={props.fromSavedPoints}
            userNotesOnChange={props.userNotesOnChange}
            userNotes={props.userNotes}
            json={props.json}
            assetData={props.assetData}
            notesFromDB={props.notesFromDB}
            qualify={props.qualify}
            disqualify={props.disqualify}
            blacklist={props.blacklist}
            disableAssetButtons={props.disableAssetButtons}
            hideButtons={hideButtons}
          />
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.sessionState.authUser,
    users: state.userState.users,
  };
};

export default connect(mapStateToProps, null)(PointDetails);
