import React from 'react';

import nocoLogo from '../../assets/images/NOCO-logo-white.png';
import classes from './Logo.module.css';

const logo = (props) => (
    <div className={classes.LogoNav} style={{height: props.height}}>
        <img src={nocoLogo} alt="NOCO - Data for decarbonization" />
    </div>
);

export default logo;