import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { Container, Row, Col } from 'react-bootstrap';

//import Navigation from '../Navigation';
import SideNav from '../Navigation/Sidebar';

import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import AssetsPage from '../UserAssets/Assets';
import DealsPage from '../Deals/Deals';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import './App.css';

const App = () => (
  <Router>
    <Container fluid>
      <Row>
        <Col md={2} lg={2} xl={2} className="noPadding">
          <SideNav />
        </Col>
        <Col md={10} lg={10} xl={10} className="noPadding">
          <Switch>
            <Route exact path={ROUTES.LANDING} component={LandingPage} />
            <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route path={ROUTES.ASSETS} component={AssetsPage} />
            <Route path={ROUTES.DEALS} component={DealsPage} />
            <Route path={ROUTES.HOME} component={HomePage} />
            <Route
              path={ROUTES.PASSWORD_FORGET}
              component={PasswordForgetPage}
            />
            <Route path={ROUTES.ACCOUNT} component={AccountPage} />
            <Route path={ROUTES.ADMIN} component={AdminPage} />
          </Switch>
        </Col>
      </Row>
    </Container>
  </Router>
);
export default compose(withFirebase, withAuthentication)(App);
