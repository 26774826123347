import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  projects: null,
  assets: null,
  loadingFlag: true,
};

const assetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PROJECTS:
      return updateObject(state, {
        projects: action.projects,
        loadingFlag: action.loadFlag,
      });
    case actionTypes.LOAD_ASSETS:
      return updateObject(state, {
        assets: action.assets,
        loadingFlag: action.loadingFlag,
      });
    case actionTypes.ASSETS_SET:
      return updateObject(state, {
        assets: action.assets,
      });
    default:
      return state;
  }
};

export default assetsReducer;
