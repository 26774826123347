import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Button, Container } from 'react-bootstrap';

import { withFirebase } from '../Firebase';

const needsEmailVerification = (authUser) =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map((provider) => provider.providerId)
    .includes('password');

const withEmailVerification = (Component) => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    render() {
      return needsEmailVerification(this.props.authUser) ? (
        <Container>
          {this.state.isSent ? (
            <p>
              Email confirmation sent: Check your email (including Spam folder)
              for a confirmation email. Refresh page once you confirmed your
              email.
            </p>
          ) : (
            <p>
              Verify your Email: Check email (Spam folder included) for
              confirmation email or send a new one.
            </p>
          )}

          <Button
            type="button"
            onClick={this.onSendEmailVerification}
            disabled={this.state.isSent}
          >
            Send confirmation E-Mail
          </Button>
        </Container>
      ) : (
        <Component {...this.props} />
      );
    }
  }

  const mapStateToProps = (state) => ({
    authUser: state.sessionState.authUser,
  });

  return compose(withFirebase, connect(mapStateToProps))(WithEmailVerification);
};

export default withEmailVerification;
