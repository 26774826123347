import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  viewport: {
    zoom: 9,
    latitude: 52.12173,
    longitude: -0.86806,
    width: '100%',
    height: '100%',
  },
  projectName: null,
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ON_VIEWPORT_CHANGED:
      return updateObject(state, {
        viewport: {
          ...state.viewport,
          ...action.viewport,
        },
      });
    default:
      return state;
  }
};

export default mapReducer;
