import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Accordion,
  Card,
  Spinner,
  Modal,
} from 'react-bootstrap';
import { connect } from 'react-redux';

import { withFirebase } from '../../Firebase';
import { saveLoadAssets /*, saveLoadProjects*/ } from '../../../store/actions';
import List from '../../Map/Drawer/ItemList/ItemList';

import UploadWorker from './UploadWorker';

const instance = new UploadWorker();

const UploadProject = ({
  //projects,
  assets,
  firebase,
  loadAssets,
  //loadProjects,
}) => {
  const [file, setFile] = useState('');
  const [name, setName] = useState('');
  const [drawerData, setDrawerData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [fileInputKey, setFileInputKey] = useState(1);
  const [modalText, setModalText] = useState('Processing data...');

  useEffect(() => {
    if (!assets.length) {
      firebase.getAllAssets().once('value', (snapshot) => {
        loadAssets(snapshot.val());
      });
    }

    // if (!projects.length) {
    //   firebase.projects().once('value', (snapshot) => {
    //     loadProjects(snapshot.val());
    //   });
    // }
  }, []);

  const handleChange = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      setFile(JSON.parse(e.target.result));

      const groupedData = groupBy(
        JSON.parse(e.target.result).features,
        'properties'
      );
      const sortedGroupedData = sortObject(groupedData);
      setDrawerData(sortedGroupedData);
    };
  };

  const handleUpload = () => {
    handleModalShow();

    const promise = new Promise(async (resolve) => {
      // Use web worker to process the data
      const processed = await instance.processProject(name, file, assets);
      firebase.project(name).set(processed.newProject);
      firebase.getAllAssets().set(processed.assets);
      resolve(processed);
    });
    promise.then(() => {
      setModalText('Data processed. Uploading...');
      setFileInputKey(Math.random() * 1.2);
      setName('');
      setDrawerData('');
      setFile('');

      setTimeout(handleModalClose, 2000);
    });
    return;
  };

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

  const groupBy = (arr, properties) => {
    if (arr) {
      return arr.reduce(function (memo, x) {
        if (!memo[x[properties].county]) {
          memo[x[properties].county] = [];
        }
        memo[x[properties].county].push(x);
        return memo;
      }, {});
    }
  };

  const sortObject = (o) =>
    Object.keys(o)
      .sort()
      .reduce((r, k) => ((r[k] = o[k]), r), {});

  const isInvalid = name === '' || file === '';

  return (
    <Container>
      <h2>Upload New Project</h2>
      <p>
        Before uploading a new project, please back-up the database (just in
        case something goes wrong).
      </p>
      <Row>
        <Col sm={12} md={6}>
          <Form>
            <Form.Group controlId="formGroupProjectName">
              <Form.Label>Project Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter project name"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formGroupUploadFile">
              <Form.File
                key={fileInputKey}
                id="uploadFile"
                label="Upload json/geojson file"
                accept=".json, .geojson"
                onChange={handleChange}
              />
            </Form.Group>
            <Button
              disabled={isInvalid}
              variant="primary"
              onClick={handleUpload}
            >
              Upload
            </Button>
          </Form>
        </Col>
        <Col sm={12} md={6}>
          <p>Validate Data</p>
          <Accordion style={{ paddingTop: '10px' }}>
            <Card className="bg-dark">
              <Accordion.Toggle as={Card.Header} eventKey={name.toString()}>
                {name || 'Project Name'} (
                {file && file.features
                  ? file.features.length
                  : 'number of assets'}
                )
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={name.toString()}>
                <Card.Body>
                  {Object.entries(drawerData).map(([county, data], index) => {
                    data.sort((a, b) => {
                      if (
                        a.properties['proprietorName'] ===
                        b.properties['proprietorName']
                      ) {
                        return (
                          b.properties['roofSize'] - b.properties['roofSize']
                        );
                      }
                      return a.properties['proprietorName'] >
                        b.properties['proprietorName']
                        ? 1
                        : -1;
                    });

                    return (
                      <Accordion key={county}>
                        <Card className="bg-dark">
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey={county.toString()}
                          >
                            {county} ({data.length})
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={county.toString()}>
                            <Card.Body>
                              <List
                                arrayData={data}
                                click={() => {
                                  return;
                                }}
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    );
                  })}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <Row>
            <Col sm={3}>
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                centered
              >
                <span className="sr-only">Loading data...</span>
              </Spinner>{' '}
            </Col>
            <Col sm={9}>
              <h3>{modalText}</h3>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* <Row>
      <h2>Manage Existing Projects</h2>
      <p>
        Before uploading a new project, please back-up the database (just in
        case something goes wrong).
      </p>
      </Row> */}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  assets: state.assetsState.assets || {},
  //projects: state.assetsState.projects || {},
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadAssets: (data) => dispatch(saveLoadAssets(data, false)),
    //loadProjects: (data) => dispatch(saveLoadProjects(data, false)),
  };
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(UploadProject);
