import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Accordion, Card, Table, Button } from 'react-bootstrap';

import { onViewportChange } from '../../store/actions';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

class CompanyTable extends Component {
  onButtonClick = (lat, long) => {
    this.props.onViewportChange({
      longitude: long,
      latitude: lat,
      zoom: 16,
    });

    this.props.history.push('/map');
  };

  render() {
    return (
      <Accordion key={this.props.name}>
        <Card className="bg-dark">
          <Accordion.Toggle as={Card.Header} eventKey="0">
            {this.props.name} ({this.props.data.length})
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>#</th>
                  <th>County</th>
                  <th>District</th>
                  <th>Estimated Capacity (kWp)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.props.data
                  .sort((a, b) => {
                    if (a.properties.county === b.properties.county) {
                      return b.properties.district - b.properties.district;
                    }
                    return a.properties.county > b.properties.county ? 1 : -1;
                  })
                  .map((asset, index) => {
                    return (
                      <tr key={index + 1}>
                        <td>{index + 1}</td>
                        <td>{asset.properties.county}</td>
                        <td>{asset.properties.district}</td>
                        <td>
                          {numberWithCommas(
                            parseInt(
                              (parseInt(asset.properties.roofSize, 10) * 140) /
                                1000,
                              10
                            )
                          )}
                        </td>
                        <td>
                          <Button
                            onClick={() =>
                              this.onButtonClick(
                                asset.properties.lat,
                                asset.properties.long
                              )
                            }
                          >
                            Show in map
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onViewportChange: (viewport) => dispatch(onViewportChange(viewport)),
  };
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(CompanyTable);
