import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Container, Spinner, Card } from 'react-bootstrap';

import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import { startLoadAssets } from '../../store/actions';
import CompanyTable from '../UserAssets/CompanyTable';

const groupBy = (arr, properties) => {
  return arr.reduce(function (memo, x) {
    if (!memo[x[properties].dealFlow.status]) {
      memo[x[properties].dealFlow.status] = [];
    }
    memo[x[properties].dealFlow.status].push(x);
    return memo;
  }, {});
};

const sortObject = (o) =>
  Object.keys(o)
    .sort()
    .reduce((r, k) => ((r[k] = o[k]), r), {});

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

class Leads extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    if (!this.props.assets.length) {
      this.setState({ loading: true });

      this.props.firebase.getAllAssets().on('value', (snapshot) => {
        this.props.loadAssets(snapshot.val());

        this.setState({ loading: false });
      });
    }
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { assets, authUser } = this.props;
    const { loading } = this.state;

    return (
      <Container>
        <h1>Deals</h1>
        {loading && (
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Loading data...</span>
          </Spinner>
        )}
        {assets[authUser.uid]
          ? Object.keys(assets[authUser.uid]).map((key) => {
              return (
                <Card
                  className="bg-dark"
                  key={key}
                  style={{ marginTop: '35px' }}
                >
                  <Card.Header>
                    <h2>
                      {key} (
                      {assets[authUser.uid][key]['qualified'].features.length})
                    </h2>
                  </Card.Header>
                  <Card.Body>
                    {Object.entries(
                      sortObject(
                        groupBy(
                          assets[authUser.uid][key]['qualified'].features,
                          'properties'
                        )
                      )
                    ).map(([company, value], index) => {
                      return (
                        <CompanyTable key={index} name={company} data={value} />
                      );
                    })}
                  </Card.Body>
                </Card>
              );
            })
          : !loading && <div>Seems you do not have any deals.</div>}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assets: state.assetsState.assets || {},
    authUser: state.sessionState.authUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAssets: () => dispatch(startLoadAssets(null, null)),
  };
};

const condition = (authUser) => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps)
)(Leads);
