import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';

const config = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

class Fb {
  constructor() {
    app.initializeApp(config);
    app.analytics();
    app.analytics().logEvent('notification_received');

    this.serverValue = app.database.ServerValue;

    this.auth = app.auth();
    this.db = app.database();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: `http://localhost:3000`,
    });

  // *** Merge Auth and DB User API ***  //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then((snapshot) => {
            const dbUser = snapshot.val();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  database = () => this.db.ref();

  // *** User API ***

  user = (uid) => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  // *** Map API ***

  userProperties = (uid) => this.db.ref(`properties/${uid}`);

  properties = () => this.db.ref('properties');

  // *** Portofolio API ***

  portofolio = (uid) => this.db.ref(`portofolios/${uid}`);

  portofolios = () => this.db.ref(`portofolios`);

  // *** Projects API ***

  projects = () => this.db.ref(`projects`);

  projectFeatures = (project) => this.db.ref(`projects/${project}/features`);

  project = (project) => this.db.ref(`projects/${project}`);

  // *** Assets API ***

  qualifyAsset = (uid, project) =>
    this.db.ref(`userProjects/${uid}/${project}/qualified/`);

  disqualifyAsset = (uid, project) =>
    this.db.ref(`userProjects/${uid}/${project}/disqualified/`);

  qualifyContacts = (uid, project) =>
    this.db.ref(`userProjects/${uid}/${project}/contacts/`);

  blacklistAssets = (uid, project) =>
    this.db.ref(`userProjects/${uid}/${project}/blacklist`);

  getUserAsset = (uid, project, type) =>
    this.db.ref(`userProjects/${uid}/${project}/${type}`);

  getUserAssetFeatures = (uid, project, type) =>
    this.db.ref(`userProjects/${uid}/${project}/${type}/features`);

  getUserAssets = (uid) => this.db.ref(`userProjects/${uid}`);

  getAllAssets = () => this.db.ref('userProjects');
}

const Firebase = new Fb();

export default Firebase;
