import React from 'react';
import { Row, Col } from 'react-bootstrap';

import nocoLogo from '../../assets/images/NOCO-logo-white.png';
import classes from './Logo.module.css';

const logo = (props) => (
  <Row className="justify-content-md-center" style={{ height: props.height }}>
    <Col className={classes.Logo}>
      <img
        className={classes.LogoImg}
        src={nocoLogo}
        alt="NOCO - Data for decarbonization"
      />
    </Col>
  </Row>
);

export default logo;
