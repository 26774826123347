import React from 'react';
import { compose } from 'recompose';
import Map from '../Map/Map';

import { withAuthorization, withEmailVerification } from '../Session';

const HomePage = () => (
  <div>
    {/* <h1>Home Page</h1>
    <p>The Home Page is accessible by every signed in user.</p> */}

    <Map />
  </div>
);

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(HomePage);
