import React from 'react';
import { Modal, Button /*, Alert*/ } from 'react-bootstrap';
import PointDetails from '../../shared/PointDetails';

import './Modal.css';

function MapModal(props) {
  return (
    <Modal show={props.show} onHide={props.onHide} size="xl" centered>
      <Modal.Body style={{ minHeight: '450px' }}>
        <PointDetails
          data={props.freehold}
          userNotesOnChange={props.userNotesOnChange}
          userNotes={props.userNotes}
          json={props.json}
          notesFromDB={props.notesFromDB}
          qualify={props.qualify}
          disqualify={props.disqualify}
          blacklist={props.blacklist}
          assetData={props.assetData}
          disableAssetButtons={props.disableAssetButtons}
        />

        <Button
          onClick={props.onHide}
          className="float-right"
          style={{ margin: '5px' }}
        >
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default MapModal;
