export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/map';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/forgot-password';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const ASSETS = '/assets';
export const DEALS = '/deals';
