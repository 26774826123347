import React from 'react';
import { Button } from 'react-bootstrap';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <Button variant="outline-danger" onClick={firebase.doSignOut}>
    <span style={{ fontSize: '1.2rem' }}>Sign Out</span>
  </Button>
);

export default withFirebase(SignOutButton);
