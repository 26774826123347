import React, { PureComponent } from 'react';
import ReactMapGL, {
  NavigationControl,
  Source,
  Layer,
  FlyToInterpolator,
} from 'react-map-gl';
import { compose } from 'recompose';
import {
  Container,
  Col,
  Row,
  Spinner,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import 'mapbox-gl/dist/mapbox-gl.css';

import {
  withAuthorization,
  withEmailVerification,
} from '../../components/Session';
import { withFirebase } from '../../components/Firebase';
import MapModal from '../../components/Map/Modal/Modal';
import helpers from '../../components/Map/helpers';
import Drawer from '../../components/Map/Drawer/Drawer';
import {
  onViewportChange,
  saveLoadProjects,
  saveLoadAssets,
} from '../../store/actions';

import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './Map.css';

import AssetsWorker from './AssetsWorker';

const workerInstance = new AssetsWorker();

const TOKEN =
  'pk.eyJ1IjoiY2Jhc25vIiwiYSI6ImNrN3ozcTFnZzAxd3UzbW85N2FvamlsdXgifQ.GnMuG6cQ3VvJr_4pfe-bOw';
const navStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px',
};

class Map extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      freehold: {
        leaseholds: null,
      },
      id: null,
      fetchedPoints: [{ key: '' }, { key: '' }],
      userNotes: '',
      notesFromDB: '',
      user: null,
      projectName: 'Leicester',
      mapLoaded: false,
      disableAssetButtons: false,
      projectsLayerIds: [],
      assetsLayerIds: [],
    };
    this.mapRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this._resize);

    this.loadData(this.props.authUser.uid);

    this._resize();
  }

  componentWillUnmount() {
    //console.log('[UNMOUNTING]', this.state);
    window.removeEventListener('resize', this._resize);
    //this.props.firebase.projects().off();
    //this.props.firebase.userProperties().off();
    const viewport = {
      transitionDuration: 0,
      transitionInterruption: 1,
    };
    this.props.firebase.projects().off();
    this.props.firebase.getAllAssets().off();
    this.props.onViewportChange(viewport);
  }

  loadData(user) {
    this.setState({ user: user });
    this.props.firebase.userProperties(user).on('value', (snapshot) => {
      const savedProperties = [];
      snapshot.forEach((childSnapshot) => {
        const data = {
          id: childSnapshot.val().id,
          key: childSnapshot.key,
          notesFromDB: childSnapshot.val().userNotes,
        };
        savedProperties.push(data);
      });
      ////console.log(savedProperties);
      this.setState({ fetchedPoints: savedProperties });
    });
  }

  loadProjects(map) {
    let newProjectsLayerIds = [];
    this.props.firebase.projects().on('value', (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const key = childSnapshot.key;
        const data = childSnapshot.val();
        newProjectsLayerIds.push(key);
        if (map !== 'undefined' && map.getLayer(key)) {
          map.getSource(key).setData(data);
        } else {
          map.addSource(key, {
            type: 'geojson',
            data: data,
            tolerance: 1,
          });

          map.addLayer({
            id: key,
            type: 'fill',
            source: key,
            paint: {
              'fill-color': 'rgba(18, 243, 81, 0.5)',
              'fill-outline-color': 'rgba(18, 243, 81, 1)',
            },
          });
        }
      });
      let projectsLayerIds = this.state.projectsLayerIds;

      if (projectsLayerIds.length > 0) {
        projectsLayerIds.forEach((layerId) => {
          if (newProjectsLayerIds.includes(layerId) === false) {
            if (map.getLayer(layerId)) map.removeLayer(layerId);
            if (map.getSource(layerId)) map.removeSource(layerId);
          }
        });
        this.setState({ projectsLayerIds: newProjectsLayerIds });
        newProjectsLayerIds = [];
      } else {
        this.setState({ projectsLayerIds: newProjectsLayerIds });
        newProjectsLayerIds = [];
      }

      const data1 = {};

      Object.assign(data1, snapshot.val());

      this.props.saveLoadProjects(data1, false);
    });
  }

  loadAssets(map, user) {
    let newAssetsLayerIds = [];
    this.props.firebase.getAllAssets().on('value', (snapshot) => {
      if (map !== null && user !== null) {
        snapshot.forEach((childSnapshot) => {
          const keyUser = childSnapshot.key;
          const data = childSnapshot.val();
          if (keyUser === user) {
            Object.keys(data).map((key) => {
              Object.keys(data[key]).map((type) => {
                const layerName =
                  childSnapshot.key.toString() +
                  '/' +
                  key.toString() +
                  '/' +
                  type.toString();
                newAssetsLayerIds.push(layerName);
                if (type === 'qualified') {
                  if (map.getLayer(layerName)) {
                    map.getSource(layerName).setData(data[key].qualified);
                  } else {
                    map.addSource(layerName, {
                      type: 'geojson',
                      data: data[key].qualified,
                      tolerance: 1,
                    });
                    map.addLayer({
                      id: layerName,
                      type: 'fill',
                      source: layerName,
                      paint: {
                        'fill-color': 'rgba(0, 0, 255, 0.5)',
                        'fill-outline-color': 'rgba(0, 0, 255, 1)',
                      },
                    });
                  }
                } else if (type === 'contacts') {
                  if (map.getLayer(layerName)) {
                    map.getSource(layerName).setData(data[key].contacts);
                  } else {
                    map.addSource(layerName, {
                      type: 'geojson',
                      data: data[key].contacts,
                      tolerance: 1,
                    });
                    map.addLayer({
                      id: layerName,
                      type: 'fill',
                      source: layerName,
                      paint: {
                        'fill-color': 'rgba(255, 255, 0, 0.5)',
                        'fill-outline-color': 'rgba(255, 255, 0, 1)',
                      },
                    });
                  }
                } else if (type === 'disqualified') {
                  if (map.getLayer(layerName)) {
                    map.getSource(layerName).setData(data[key].disqualified);
                  } else {
                    map.addSource(layerName, {
                      type: 'geojson',
                      data: data[key].disqualified,
                      tolerance: 1,
                    });
                    map.addLayer({
                      id: layerName,
                      type: 'fill',
                      source: layerName,
                      paint: {
                        'fill-color': 'rgba(255, 0, 68, 0.5)',
                        'fill-outline-color': 'rgba(255, 0, 68, 1)',
                      },
                    });
                  }
                } else {
                  if (map.getLayer(layerName)) {
                    map.getSource(layerName).setData(data[key].blacklist);
                  } else {
                    map.addSource(layerName, {
                      type: 'geojson',
                      data: data[key].blacklist,
                      tolerance: 1,
                    });
                    map.addLayer({
                      id: layerName,
                      type: 'fill',
                      source: layerName,
                      paint: {
                        'fill-color': 'rgba(0, 0, 0, 0.5)',
                        'fill-outline-color': 'rgba(0, 0, 0, 1)',
                      },
                    });
                  }
                }
              });
            });
          } else {
            Object.keys(data).map((key) => {
              Object.keys(data[key]).map((type) => {
                const layerName =
                  childSnapshot.key.toString() +
                  '/' +
                  key.toString() +
                  '/' +
                  type.toString();
                newAssetsLayerIds.push(layerName);
                if (type === 'disqualified') {
                  if (map.getLayer(layerName)) {
                    map.getSource(layerName).setData(data[key].disqualified);
                  } else {
                    map.addSource(layerName, {
                      type: 'geojson',
                      data: data[key].disqualified,
                      tolerance: 1,
                    });
                    map.addLayer({
                      id: layerName,
                      type: 'fill',
                      source: layerName,
                      paint: {
                        'fill-color': 'rgba(255, 0, 68, 0.5)',
                        'fill-outline-color': 'rgba(255, 0, 68, 1)',
                      },
                    });
                  }
                } else if (type === 'blacklist') {
                  if (map.getLayer(layerName)) {
                    map.getSource(layerName).setData(data[key][type]);
                  } else {
                    map.addSource(layerName, {
                      type: 'geojson',
                      data: data[key][type],
                      tolerance: 1,
                    });
                    map.addLayer({
                      id: layerName,
                      type: 'fill',
                      source: layerName,
                      paint: {
                        'fill-color': 'rgba(0, 0, 0, 0.5)',
                        'fill-outline-color': 'rgba(0, 0, 0, 1)',
                      },
                    });
                  }
                } else {
                  if (map.getLayer(layerName)) {
                    map.getSource(layerName).setData(data[key][type]);
                  } else {
                    map.addSource(layerName, {
                      type: 'geojson',
                      data: data[key][type],
                      tolerance: 1,
                    });
                    map.addLayer({
                      id: layerName,
                      type: 'fill',
                      source: layerName,
                      paint: {
                        'fill-color': 'rgba(255, 161, 0, 0.5)',
                        'fill-outline-color': 'rgba(255, 161, 0, 1)',
                      },
                    });
                  }
                }
              });
            });
          }
        });
      }
      let assetsLayerIds = this.state.assetsLayerIds;

      if (assetsLayerIds.length > 0) {
        assetsLayerIds.forEach((layerId) => {
          if (newAssetsLayerIds.includes(layerId) === false) {
            if (map.getLayer(layerId)) map.removeLayer(layerId);
            if (map.getSource(layerId)) map.removeSource(layerId);
          }
        });
        this.setState({ assetsLayerIds: newAssetsLayerIds });
        newAssetsLayerIds = [];
      } else {
        this.setState({ assetsLayerIds: newAssetsLayerIds });
        newAssetsLayerIds = [];
      }

      const data1 = {};

      Object.assign(data1, snapshot.val());

      saveLoadAssets(data1, false);
    });
  }

  blacklistAsset(user, assetData) {
    this.setState({ disableAssetButtons: true });
    new Promise(async (resolve) => {
      // Use a web worker to process the data
      const processed = await workerInstance.blacklist(
        user,
        assetData,
        this.props.assets,
        this.props.projects
      );
      this.props.firebase.database().update(processed);

      resolve(processed);
    }).then(() => {
      this.setModalShow(false);
      this.setState({ disableAssetButtons: false });
    });

    return;
  }

  disqualifyAsset(user, assetData, reason) {
    this.setState({ disableAssetButtons: true });

    new Promise(async (resolve) => {
      // Use a web worker to process the data
      const processed = await workerInstance.disqualify(
        user,
        assetData,
        this.props.assets,
        this.props.projects,
        reason
      );
      this.props.firebase.database().update(processed);

      resolve(processed);
    }).then(() => {
      this.setModalShow(false);
      this.setState({ disableAssetButtons: false });
    });

    return;
  }

  qualifyAsset(user, assetData) {
    this.setState({ disableAssetButtons: true });

    new Promise(async (resolve) => {
      // Use a web worker to process the data
      const processed = await workerInstance.qualify(
        user,
        assetData,
        this.props.assets,
        this.props.projects
      );
      this.props.firebase.database().update(processed);
      resolve();
    }).then(() => {
      this.setModalShow(false);
      this.setState({ disableAssetButtons: false });
    });

    return;
  }

  removeLayer(map, uid, projectName, type) {
    if (uid === null && type === null) {
      if (map.getLayer(projectName)) map.removeLayer(projectName);
      if (map.getSource(projectName)) map.removeSource(projectName);
    } else {
      if (map.getLayer(uid + '/' + projectName + '/' + type)) {
        map.removeLayer(uid + '/' + projectName + '/' + type);
        map.removeSource(uid + '/' + projectName + '/' + type);
      }
    }
  }

  _loadData() {
    setTimeout(this.setState({ mapLoaded: true }), 2000);
    if (this.mapRef) {
      this.loadProjects(this.mapRef.getMap());
      this.loadAssets(this.mapRef.getMap(), this.props.authUser.uid);
      //this.props.loadProjects();
      //this.props.loadAssets();
    }
  }

  _resize = () => {
    this.props.onViewportChange({
      width: '100%',
      height: '100vh',
    });
  };

  _flyToHandler = (lat, long, projectName) => {
    let duration = 1700;
    if (projectName === this.state.projectName) {
      duration = 1700;
    } else {
      duration = 8000;
      this.setState({ projectName: projectName });
    }
    const viewport = {
      longitude: long,
      latitude: lat,
      zoom: 16,
      transitionDuration: duration,
      transitionInterpolator: new FlyToInterpolator(),
    };
    this.props.onViewportChange(viewport);
  };

  _onClickHandler = (map) => {
    this.setState({
      freehold: {
        leaseholds: null,
        userNotes: '',
        buttonAlert: '',
      },
    });

    this.loadData(this.state.user);

    let FID;
    let property;
    let leases;
    let jsonData;
    const features = map.features;
    //console.log('[MAP ONCLICK]', features);

    if (
      features[1] &&
      features[1].source === 't1_all_source' &&
      features[1].properties.fid
    ) {
      FID = parseInt(features[1].properties.fid);
      this.setState({ id: FID });
      //console.log('THIS IS FID', FID);
      property = helpers.propertyData(
        features
          .filter((x) => x.properties.fid === FID)
          .filter((x) => x.source === 'freeholds_source_CCOD')
      );
      leases = helpers.leasesData(
        features
          .filter((x) => x.properties.fid === FID)
          .filter((x) => x.source === 'leaseholds_source_CCOD')
      );
      jsonData = features[1].properties;
    } else if (
      features[0] &&
      features[0].source !== 'undefined' &&
      features[1].source === 't1_all_source' &&
      features[1].properties.fid
    ) {
      FID = parseInt(features[0].properties.fid);
      this.setState({ id: FID });
      property = helpers.propertyData(
        features
          .filter((x) => x.properties.fid === FID)
          .filter((x) => x.source === 'freeholds_source_CCOD')
      );
      leases = helpers.leasesData(
        features
          .filter((x) => x.properties.fid === FID)
          .filter((x) => x.source === 'leaseholds_source_CCOD')
      );
      jsonData = features[1].properties;
    }

    if (
      features[0] &&
      features[0].layer &&
      this.props.projects[features[0].layer.id]
    ) {
      this.props.projects[features[0].layer.id].features.map(
        (feature, index) => {
          if (feature.properties.fid === FID.toString()) {
            this.setState({
              assetData: {
                index: index,
                projectName: features[0].layer.id,
                feature: feature,
                companyNumber: features[0].properties.companyNumber,
              },
            });
          }
        }
      );
    } else if (
      features[0] &&
      features[0].source !== 'undefined' &&
      features[1] &&
      features[1].source === 't1_all_source' &&
      features[1].properties.fid
    ) {
      this.props.assets[features[0].source.split('/')[0]][
        features[0].source.split('/')[1]
      ][features[0].source.split('/')[2]].features.map((feature, index) => {
        if (feature.properties.fid === FID.toString()) {
          this.setState({
            assetData: {
              index: index,
              projectName: features[0].layer.id,
              feature: feature,
              companyNumber: features[0].properties.companyNumber,
            },
          });
        }
      });
    }

    if (this.state.id !== null) {
      let dataz = this.state.fetchedPoints.find(
        (o) => o.id === this.state.id.toString()
      )
        ? this.state.fetchedPoints.find(
            (o) => o.id === this.state.id.toString()
          ).notesFromDB
        : null;
      this.setState({ userNotes: dataz });
    }
    this.setState({
      jsonData: jsonData,
      freehold: {
        ...property,
        leaseholds: leases,
      },
    });

    if (!FID) {
      this.hideDetails();
    } else {
      this.setModalShow(true);
    }
  };

  setModalShow(e, user) {
    if (!e) this.hideDetails();
    this.setState({ modalShow: e });
  }

  hideDetails = () => {
    this.setState({
      freehold: {
        leaseholds: null,
      },
      userNotes: '',
      buttonAlert: '',
    });
  };

  newOrOld(user) {
    let data = this.state.fetchedPoints.find(
      (o) => o.id === this.state.id.toString()
    )
      ? this.state.fetchedPoints.find((o) => o.id === this.state.id.toString())
      : null;

    if (data) {
      this.updateExisting(user, data);
    } else {
      this.savePoint(user);
    }
  }

  updateExisting(user, data) {
    if (this.state.userNotes !== this.state.fetchedPoints.notesFromDB) {
      this.props.firebase.userProperties(user.uid + '/' + data.key).update({
        createdAt: this.props.firebase.serverValue.TIMESTAMP,
        userNotes: this.state.userNotes,
        id: this.state.jsonData.fid,
      });
    }
  }

  savePoint = (user) => {
    this.props.firebase.userProperties(user.uid).push({
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
      userNotes: this.state.userNotes,
      id: this.state.jsonData.fid,
    });
  };

  onChangeUserNotes = (event) => {
    const text = event.target.value;
    this.setState({
      userNotes: text,
    });
  };

  render() {
    const popoverLegend = (
      <Popover id="popover-basic">
        <Popover.Content style={{ backgroundColor: '#343a40', color: 'white' }}>
          <Container fluid>
            <Row
              style={{
                margin: '5px',
                border: '1px solid',
                borderRadius: '10px',
                borderColor: 'rgba(18, 243, 81, 1)',
                padding: '5px',
              }}
            >
              <span
                style={{ color: 'rgba(18, 243, 81, 1)', marginRight: '5px' }}
              >
                Green{' '}
              </span>
              <span>Unprocessed asset</span>
            </Row>
            <Row
              style={{
                margin: '5px',
                border: '1px solid',
                borderRadius: '10px',
                borderColor: 'rgba(0, 0, 255, 1)',
                padding: '5px',
              }}
            >
              <span style={{ color: 'rgba(0, 0, 255, 1)', marginRight: '5px' }}>
                Blue{' '}
              </span>
              <span>Qualified asset</span>
            </Row>
            <Row
              style={{
                margin: '5px',
                border: '1px solid',
                borderRadius: '10px',
                borderColor: 'rgba(255, 255, 0, 1)',
                padding: '5px',
              }}
            >
              <span
                style={{ color: 'rgba(255, 255, 0, 1)', marginRight: '5px' }}
              >
                Yellow{' '}
              </span>
              <span>
                Unprocessed building that belongs to a qualified company
              </span>
            </Row>
            <Row
              style={{
                margin: '5px',
                border: '1px solid',
                borderRadius: '10px',
                borderColor: 'rgba(255, 0, 68, 1)',
                padding: '5px',
              }}
            >
              <span
                style={{ color: 'rgba(255, 0, 68, 1)', marginRight: '5px' }}
              >
                Red{' '}
              </span>
              <span>Disqualified asset</span>
            </Row>
            <Row
              style={{
                margin: '5px',
                border: '1px solid',
                borderRadius: '10px',
                borderColor: 'rgba(255, 161, 0, 1)',
                padding: '5px',
              }}
            >
              <span
                style={{ color: 'rgba(255, 161, 0, 1)', marginRight: '5px' }}
              >
                Orange{' '}
              </span>
              <span>
                Building owned by a company that has been qualified by another
                user in your organisation.
              </span>
            </Row>
            <Row
              style={{
                margin: '5px',
                border: '1px solid',
                borderRadius: '10px',
                borderColor: 'rgba(0, 0, 0, 1)',
                padding: '5px',
              }}
            >
              <span style={{ color: 'rgba(0, 0, 0, 1)', marginRight: '5px' }}>
                Black{' '}
              </span>
              <span>Blacklisted company</span>
            </Row>
            <Row
              style={{
                margin: '5px',
                border: '1px solid',
                borderRadius: '10px',
                borderColor: 'rgba(282, 283, 200, 0.3)',
                padding: '5px',
              }}
            >
              <span
                style={{
                  color: 'rgba(282, 283, 200, 0.3)',
                  marginRight: '5px',
                }}
              >
                Grey{' '}
              </span>
              <span>Buildings</span>
            </Row>
          </Container>
        </Popover.Content>
      </Popover>
    );
    return (
      <Container fluid>
        <Row>
          <Col
            md={9}
            className={'mapContainer '}
            ref={(element) => (this.divRef = element)}
            style={{ padding: '0px' }}
          >
            <ReactMapGL
              ref={(map) => (this.mapRef = map)}
              {...this.props.viewport}
              mapStyle="mapbox://styles/cbasno/ck87j7fur13sd1iqghnp9y2ec"
              //mapStyle="mapbox://styles/cbasno/cklpjeyhe135y18ns6jga9eya"
              mapboxApiAccessToken={TOKEN}
              onClick={this.state.mapLoaded ? this._onClickHandler : null}
              onLoad={() => this._loadData()}
              onViewportChange={(viewport) =>
                this.props.onViewportChange(viewport)
              }
            >
              <Row
                style={{
                  zIndex: '500',
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  margin: '5px',
                }}
              >
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="left"
                  overlay={popoverLegend}
                >
                  <div
                    style={{
                      backgroundColor: '#343a40',
                      padding: '5px',
                      borderRadius: '10px',
                    }}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} /> Map legend
                  </div>
                </OverlayTrigger>
              </Row>
              <div className="nav" style={navStyle}>
                <NavigationControl
                  onViewportChange={(viewport) =>
                    this.props.onViewportChange(viewport)
                  }
                />
              </div>
              <Source
                id="buildings_source"
                type="vector"
                url="mapbox://cbasno.bcn0qnas"
              />
              <Source
                id="freeholds_source_CCOD"
                type="vector"
                url="mapbox://cbasno.9zf8hw41"
              />
              <Source
                id="leaseholds_source_CCOD"
                type="vector"
                url="mapbox://cbasno.dnvnjtzq"
              />
              <Source
                id="t1_all_source"
                type="vector"
                url="mapbox://cbasno.b5bx6p5h"
              />
              <Layer
                id="freeholds_layer_CCOD"
                type="fill"
                source="freeholds_source_CCOD"
                source-layer="CCOD_FREEHOLDS_FID-0povdm"
                paint={{
                  'fill-color': 'rgba(253, 38, 238, 0)',
                  'fill-outline-color': 'rgba(253, 38, 238, 0)',
                }}
              />
              <Layer
                id="leaseholds_layer_CCOD"
                type="fill"
                source="leaseholds_source_CCOD"
                source-layer="CCOD_LEASEHOLDS_FID-9yvfns"
                paint={{
                  'fill-color': 'rgba(52, 230, 254, 0)',
                  'fill-outline-color': 'rgba(52, 230, 254, 0)',
                }}
              />
              <Layer
                id="buildings_layer"
                type="fill"
                source="buildings_source"
                source-layer="Buildings"
                paint={{
                  'fill-color': 'rgba(282, 283, 200, 0.1)',
                  'fill-outline-color': 'rgba(282, 283, 200, 0.3)',
                }}
              />
              <Layer
                id="t1_all_source"
                type="fill"
                source="t1_all_source"
                source-layer="T1_All-042ydw"
                paint={{
                  'fill-color': 'rgba(282, 283, 200, 0)',
                  'fill-outline-color': 'rgba(282, 283, 200, 0)',
                }}
              />
            </ReactMapGL>
          </Col>
          <Col
            md={3}
            style={{
              padding: '0px',
              height: '100vh',
              maxHeight: '100vh',
              overflowY: 'auto',
            }}
          >
            <h3 style={{ margin: '10px' }}> Assets </h3>
            {!this.props.loadingFlag ? (
              <Drawer
                projects={this.props.projects}
                click={this._flyToHandler}
                authUser={this.props.authUser}
              />
            ) : (
              <Container>
                <Spinner animation="border" role="status" variant="primary">
                  <span className="sr-only">Loading data...</span>
                </Spinner>
              </Container>
            )}
          </Col>
        </Row>
        <MapModal
          freehold={this.state.freehold}
          show={this.state.modalShow}
          onHide={() => {
            this.newOrOld(this.props.authUser);
            this.setModalShow(false);
          }}
          onButtonSave={() => this.newOrOld(this.props.authUser)}
          userNotesOnChange={this.onChangeUserNotes}
          userNotes={this.state.userNotes}
          notesFromDB={this.state.notesFromDB}
          json={this.state.jsonData}
          assetData={this.state.assetData}
          onLoad={() => this._loadData}
          alert={this.state.buttonAlert}
          qualify={() =>
            this.qualifyAsset(this.props.authUser, this.state.assetData)
          }
          disqualify={(reason) =>
            this.disqualifyAsset(
              this.props.authUser,
              this.state.assetData,
              reason
            )
          }
          blacklist={() =>
            this.blacklistAsset(this.props.authUser, this.state.assetData)
          }
          disableAssetButtons={this.state.disableAssetButtons}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.assetsState.projects,
    assets: state.assetsState.assets,
    loadingFlag: state.assetsState.loadingFlag,
    authUser: state.sessionState.authUser,
    viewport: state.mapState.viewport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveLoadProjects: (data, loadingFlag) =>
      dispatch(saveLoadProjects(data, loadingFlag)),
    saveLoadAssets: (data, loadingFlag) =>
      dispatch(saveLoadAssets(data, loadingFlag)),
    onViewportChange: (viewport) => dispatch(onViewportChange(viewport)),
  };
};

const condition = (authUser) => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps)
)(Map);
